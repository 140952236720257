let barLargeClass = 'hs-bar_large',
    barMobileLargeClass = 'hs-bar_largeMobile',
    barCollapsedClass = 'hs-bar_collapsed',
    barMainClass = '.hs-bar',
    barControllClass = '.hs-bar-control';

class constructorBar {
    /**
     * Init common scripts
     */
    static initModal() {
        constructorBar.initButtons();
        constructorBar.setHeightBar();
        customizeBar.generateSignals();
        constructorBar.initSlider();
        constructorBar.signalsOnInit();
        constructorBar.onIframeDisabled();
    }

    /**
     * Init interface buttons
     * */
    static initButtons() {
        // collapse bar on click button
        $(document).on('click', '[data-collapse-bar]', function () {
            constructorBar.collapseBar();
            event.preventDefault();
            constructorBar.setSignalsPosition()
        })
        $(document).on('click', '[data-tab-control]', function () {
            let tabId = $(this).attr('data-tab-control'),
                tabIdClose = $(this).attr('data-tab-control-close'),
                iconCloseClass = 'hs-bar-icon-close'

            if ($(this).hasClass(iconCloseClass)) {
                $(barControllClass).find('span').removeClass(iconCloseClass)
                $(this).removeClass(iconCloseClass)
            }
            else {
                $(barControllClass).find('span').removeClass(iconCloseClass)
                $(this).addClass(iconCloseClass)
            }

            constructorBar.setHeightBar();
            constructorBar.openTab(tabId, tabIdClose ? tabIdClose : false);
            constructorBar.setSignalsPosition()


        });
    }

    /**
     * Collapse or un-collapse bar
     * @setState {boolean} if true open, if null - check hs.bar
     * */
    static collapseBar(setState) {
        if (setState && setState === true || $(barMainClass).hasClass(barCollapsedClass)) {
            $(barMainClass).removeClass(barCollapsedClass)
        }
        else {
            $(barMainClass).addClass(barCollapsedClass)
            constructorBar.setSignalsPosition(300)
        }
    }

    /**
     * Set height for hs-bar
     * @height {boolean} if true set large, if null or false remove large
     * */
    static setHeightBar(height) {
        let hsBarInstallVisibility = $(barMainClass + ' .hs-bar-install'),
            hsBarCustomizeVisibility = $(barMainClass + ' .hs-bar-customize')
        if (hsBarInstallVisibility.is(':visible') || height === true) {
            $(barMainClass).removeClass(barMobileLargeClass)
            $(barMainClass).addClass(barLargeClass)
        }
        else if (hsBarCustomizeVisibility.is(':visible') && window.innerHeight < 768) {
            $(barMainClass).removeClass(barLargeClass)
            $(barMainClass).addClass(barMobileLargeClass)
        }
        else {
            $(barMainClass).removeClass(barLargeClass)
            $(barMainClass).removeClass(barMobileLargeClass)
        }
        constructorBar.setSignalsPosition(500)
    }

    /**
     * Open tab
     * @tabId {string} show this tab
     */
    static openTab(tabId, tabIdClose) {
        let tabClass = '.hs-bar-tab';
        if ($(tabId).is(':visible') === false) {
            $(tabClass).fadeOut(0);
            $(tabId).fadeIn();
        }
        else if (tabIdClose) {
            $(tabId).fadeOut(0)
            $(tabIdClose).fadeIn()
        }
        constructorBar.collapseBar(true);
        constructorBar.setHeightBar();

    }

    /**
     * Init interface buttons
     * */
    static initSlider() {
        try {
            const swiper = new Swiper('.swiper-container', {
                slidesPerView: 'auto',
                spaceBetween: 20,
                freeMode: false,
                loop: false,
                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev',
                }
            })
        } catch (e) {
            console.warn('swiper not found')
        }

    }

    static setSignalsPosition(timeout) {
        if ($('.hs-bar').hasClass(barCollapsedClass)) {
            setTimeout(function () {
                $('.hs').css('bottom', '70px')

            }, timeout ? 300 : timeout)
        }
        else {
            setTimeout(function () {
                let hsBarHeight = $('.hs-bar').height();
                $('.hs').css('bottom', hsBarHeight + 70)

            }, timeout ? 300 : timeout)
        }

    }

    static signalsOnInit() {
        document.addEventListener('hoversignal.render', function (e) {
            constructorBar.setSignalsPosition()
            constructorBar.inputHiddenProps()
            $('.hs-bar-customize-colors-list.loading').removeClass('loading');
            console.warn('HoverSignal init event received! Event:', e, '; HsModal instance:', e.hsModal)
        }, false)
    }

    static inputHiddenProps() {
        let signalObject = Object.assign({}, hs.config.signals[0]);
        signalObject.icon = ''
        $('#hsBarForm input[name="signalObject"]').val(JSON.stringify(hs.config.signals[0]))
        $('#hsBarForm input[name="accentColor"]').val(hs.config.accentColor)

        if (localStorage.getItem('invite') !== 'undefined') {
            $('#hsBarForm input[name="referredBy"]').val(localStorage.getItem('invite'))
        }
        if (constructorBar.getParametr('siteUrl') !== 'undefined') {
            $('#hsBarForm input[name="siteDomain"]').val(constructorBar.getParametr('siteUrl'))
        }
    }

    static onIframeDisabled() {
        try {
            let userWidth = window.innerWidth,
                userHeight = window.innerHeight,
                getUrl = constructorBar.getParametr('siteUrl').replace('http://', '').replace('https://', ''),
                url = 'http://app.hoversignal.com/api/screenshot?url=' + encodeURIComponent('http://' + getUrl) + '&width=' + userWidth + '&height=' + userHeight
            $('#siteImage').attr('src', url)
        }
        catch (e) {
            console.warn('siteUrl prop not found')
        }
    }

    static getParametr(sParam) {
        let sPageURL = decodeURIComponent(window.location.search.substring(1)),
            sURLVariables = sPageURL.split('&'),
            sParameterName,
            i;

        for (i = 0; i < sURLVariables.length; i++) {
            sParameterName = sURLVariables[i].split('=');

            if (sParameterName[0] === sParam) {
                return sParameterName[1] === undefined ? true : sParameterName[1];
            }
        }
    }
}

$(document).ready(() => {
    constructorBar.initModal();
    // todo remove it
    if (constructorBar.getParametr('signals') === undefined) {
        constructorBar.collapseBar();
        constructorBar.openTab('#hs-bar-tab-select');
    }
})
